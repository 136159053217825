/* eslint-disable consistent-return */
import React, { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import dynamic from "next/dynamic";
import { fetchUserAttributes } from "aws-amplify/auth";
import ContentLayoutFooterNoChartreuse from "../components/Layout/ContentLayoutFooterNoChartreuse";

const LoginComponent = dynamic(() => import("../components/Login"));

function Login() {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { authStatus, user, route, error } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
    context.route,
    context.error,
  ]);
  const [showSignOutMessage, setShowSignOutMessage] = useState(false);
  const from = router.query?.from || "/";

  useEffect(() => {
    async function loginMetric(email) {
      try {
        // Submit the body to the API
        const res = await fetch("/api/klaviyo/loginMetric", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        });
        return res;
      } catch (err) {
        console.error(err);
        return err;
      }
    }
    async function createUser() {
      try {
        // Submit the body to the API
        const res = await fetch("/api/createUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res;
      } catch (err) {
        console.error(err);
        return err;
      }
    }
    async function handleFetchUserAttributesEmail() {
      const userAttributes = await fetchUserAttributes();
      return userAttributes.email;
    }
    if (route === "authenticated") {
      const email = handleFetchUserAttributesEmail();
      loginMetric(email);
      createUser();
      router.replace(from.toString());
    }
  }, [from, authStatus, route, router, user]);

  const timerRef = useRef(null);
  const sendMessage = () => {
    timerRef.current = setTimeout(() => setShowSignOutMessage(false), 10000);
  };

  useEffect(
    () =>
      // Clear the interval when the component unmounts
      () =>
        clearTimeout(timerRef.current),
    [],
  );

  useEffect(() => {
    if (route === "signOut") {
      setShowSignOutMessage(true);
      sendMessage();
    }
  }, [route]);

  return (
    <ContentLayoutFooterNoChartreuse title="Login">
      {showSignOutMessage && (
        <div className="flex justify-center text-green-600">
          Successfully signed out!
        </div>
      )}
      <LoginComponent />
    </ContentLayoutFooterNoChartreuse>
  );
}

export default Login;

export async function getStaticProps() {
  return {
    props: { page: "Login" },
  };
}
